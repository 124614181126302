<template>
  <div v-if="getGDriveAccessStatus === 'denied'">
    <v-card class="py-6 px-6" color="white">
      <v-card-title>
        <v-avatar rounded="0">
          <v-img
            width="70"
            :src="require('../assets/logo_drive_2020q4_color_2x_web_64dp.png')"
          ></v-img>
        </v-avatar>
        <span color="black"> Google Drive</span>
      </v-card-title>

      <v-card-text>
        <p class="mt-6">
          wotMatters stores all of your notes in a Google Drive folder of your
          choice.
        </p>
        <p class="mt-6">
          To get started, please allow wotMatters to access these files in your
          Google Drive.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="ml-2"
          variant="flat"
          color="primary"
          @click="startGoogleDriveAuthorize"
        >
          Allow
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>

  <div v-else-if="getGDriveAccessStatus === 'pending'" class="text-center">
    <v-icon color="white" size="x-large" class="mt-12"> mdi-notebook </v-icon>
    <p style="max-width: 200px; margin: auto; color: white"></p>

    <v-progress-circular
      class="mt-6"
      model-value="20"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>

  <div v-else-if="!getDriveAppFolder" class="text-center">
    <v-icon color="white" size="x-large" class="mt-12"> mdi-notebook </v-icon>
    <p style="max-width: 200px; margin: auto; color: white" class="mt-6">
      Please create a folder for wotMatters to use to store your notes
    </p>
    <v-text-field
      class="mt-5 primary"
      style="max-width: 200px; margin: auto; color: white"
      label="Folder Name"
      v-model="newFolderName"
      base-color="white"
      color="white"
      variant="outlined"
      prepend-inner-icon="mdi-folder"
    ></v-text-field>

    <v-btn
      class="mt-3"
      color="primary"
      @click="createAppFolder(this.newFolderName)"
    >
      Create Folder
    </v-btn>
  </div>

  <div v-else-if="getGDriveAccessStatus === 'granted'">
    <div
      v-if="
        itemFiles &&
        itemFiles.length == 0 &&
        fileSearchText &&
        fileSearchText.length == 0
      "
      class="text-center"
    >
      <v-icon color="white" size="x-large" class="mt-12"> mdi-notebook </v-icon>
      <p style="max-width: 200px; margin: auto; color: white" class="mt-6">
        No Drive Files exist for this Work Item yet
      </p>
      <div v-if="showAddNote == 'true'">
        <wmAddDriveFileButton
          @requestCreateDriveDoc="createDriveDoc"
          @requestUploadDriveFile="uploadDriveFile"
        ></wmAddDriveFileButton>
      </div>
    </div>
    <div v-else>
      <v-row no-gutters class="px-0">
        <v-text-field
          class="primary mr-2"
          style="color: white"
          label="Search text"
          v-model="fileSearchText"
          base-color="white"
          color="white"
          variant="outlined"
          clearable
          density="compact"
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keydown.enter="refreshFiles"
          @click:clear="fileSearchText = ''"
        ></v-text-field>

        <v-btn
          color="secondary"
          variant="outlined"
          icon
          size="x-small"
          style="margin-top: 5px"
          @click="refreshFiles"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <div v-if="showAddNote == 'true'" class="ml-2">
          <wmAddDriveFileButton
            @requestCreateDriveDoc="createDriveDoc"
            @requestUploadDriveFile="uploadDriveFile"
          ></wmAddDriveFileButton>
        </div>
      </v-row>

      <div v-if="itemFiles">
        <v-expansion-panels
          class="px-0 mt-3"
          focusable
          multiple
          v-model="panelsSelected"
          style="max-height: calc(100vh - 155px); overflow-y: auto"
        >
          <v-expansion-panel
            v-for="(item, index) in itemFiles"
            :key="index"
            :item="item"
            :index="index"
          >
            <v-expansion-panel-title disable-icon-rotate class="pa-2">
              <!-- Hack: not sure why long filenames blow out list item size, so I've put in 90% -->
              <v-list-item class="pl-0" style="max-width: 90%">
                <template v-slot:prepend>
                  <v-btn
                    icon
                    class="mr-4"
                    @click.stop="handleSelectDriveDoc(item)"
                  >
                    <v-img
                      width="20"
                      aspect-ratio="1/1"
                      :src="item.iconLink"
                      :class="fileIsOpen(item.id) ? '' : 'img-black-and-white'"
                    ></v-img>
                  </v-btn>
                </template>

                <v-list-item-title>
                  <div v-if="item.followups && item.followups.length > 0">
                    <v-tooltip text="Follow up" location="top">
                      <template v-slot:activator="{ props }">
                        <v-badge
                          v-bind="props"
                          color="primary"
                          :content="item.followups.length"
                          inline
                        >
                          <div style="margin-left: -4px">
                            {{ item.name }} &nbsp;
                          </div>
                        </v-badge>
                      </template>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    {{ item.name }}
                  </div>
                </v-list-item-title>

                <v-list-item-subtitle>{{
                  moment(item.modifiedTime).fromNow()
                }}</v-list-item-subtitle>
              </v-list-item>

              <template v-slot:actions>
                <v-menu
                  v-model="showFileMenu[index]"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props">
                      <v-icon color="primary"> mdi-menu </v-icon>
                    </v-btn>
                  </template>

                  <v-list v-show="!showFileMenuRenameFile">
                    <!-- v-show="
                              !showFileMenuMoveTargetTask &&
                              !showFileMenuRenameFile
                            " -->

                    <!-- <v-list-item
                              link
                              @click="showFileMenuMoveTargetTask = true"
                            >
                              <v-icon color="grey lighten-1"
                                >mdi-arrow-right</v-icon
                              >
                              <v-list-item-title class="ml-3"
                                >Move</v-list-item-title
                              >
                            </v-list-item>

                            <v-divider></v-divider> -->

                    <v-list-item
                      link
                      @click="startRenameFile(item)"
                      title="Rename"
                    >
                      <template v-slot:prepend>
                        <v-icon color="grey lighten-1">mdi-form-textbox</v-icon>
                      </template>
                    </v-list-item>

                    <v-list-item
                      link
                      @click="
                        removeDriveFile(item);
                        showFileMenu[index] = false;
                      "
                      title="Delete"
                    >
                      <template v-slot:prepend>
                        <v-icon color="error lighten-1"
                          >mdi-window-close</v-icon
                        >
                      </template>
                    </v-list-item>

                    <v-list-item
                      link
                      @click="
                        handleCloseDriveDoc(item);
                        showFileMenu[index] = false;
                      "
                      title="Close"
                    >
                      <template v-slot:prepend>
                        <v-icon color="grey lighten-1">mdi-window-close</v-icon>
                      </template>
                    </v-list-item>
                  </v-list>

                  <!-- <v-card v-show="showFileMenuMoveTargetTask">
                            <v-list>
                              <v-list-item
                                disabled
                                @click="showFileMenuMoveTargetTask = true"
                              >
                                <v-icon color="grey lighten-1"
                                  >mdi-arrow-right</v-icon
                                >
                                <v-list-item-title class="ml-3"
                                  >Move</v-list-item-title
                                >
                              </v-list-item>

                              <v-card-text class="py-0">
                                <v-alert
                                  v-show="item.eventId"
                                  color="#1F3451"
                                  rounded
                                  icon="mdi-alert-circle-outline"
                                  max-width="325"
                                  class="mr-2"
                                >
                                  <div class="body-2">
                                    This note was created from an event.
                                  </div>
                                  <div class="body-2">
                                    Moving it will also move any other notes
                                    linked to this event.
                                  </div>
                                </v-alert>

                                <v-autocomplete
                                  ref="targetTaskControl"
                                  v-model="targetTaskId"
                                  :rules="[
                                    () =>
                                      (showFileMenu[index] && !!targetTaskId) ||
                                      'This field is required',
                                  ]"
                                  label="To task"
                                  auto-select-first
                                  clearable
                                  :items="targetTasks"
                                  item-text="title"
                                  item-value="id"
                                  class="ml-1 mr-2"
                                ></v-autocomplete>

                                <v-card-actions>
                                  <v-spacer></v-spacer>

                                  <v-btn
                                    color="primary"
                                    text
                                    @click="
                                      startMoveNote(
                                        openTaskId,
                                        item,
                                        targetTaskId
                                      )
                                    "
                                  >
                                    Move
                                  </v-btn>
                                </v-card-actions>
                              </v-card-text>
                            </v-list>
                          </v-card> -->

                  <v-card v-show="showFileMenuRenameFile">
                    <v-form v-model="isRenameValid">
                      <v-list>
                        <v-list-item disabled title="Rename">
                          <template v-slot:prepend>
                            <v-icon color="grey lighten-1"
                              >mdi-form-textbox</v-icon
                            >
                          </template>
                        </v-list-item>

                        <v-card-text class="py-0">
                          <v-textarea
                            dense
                            rows="1"
                            auto-grow
                            class="subtitle-1 font-weight-regular"
                            v-model="newFileName"
                            background-color="transparent"
                            :rules="[
                              () =>
                                (showFileMenu[index] && !!newFileName) ||
                                'This field is required',
                            ]"
                          >
                          </v-textarea>

                          <v-card-actions class="pr-0">
                            <v-spacer></v-spacer>

                            <v-btn
                              color="primary"
                              text
                              :disabled="!isRenameValid"
                              @click="renameDriveFile(item, newFileName)"
                            >
                              Rename
                            </v-btn>
                          </v-card-actions>
                        </v-card-text>
                      </v-list>
                    </v-form>
                  </v-card>
                </v-menu>
              </template>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <div v-if="item.followups && item.followups.length > 0">
                <v-list
                  v-for="(followup, index) in item.followups"
                  :key="index"
                  :item="followup"
                  :index="index"
                >
                  <v-list-item
                    class="pl-0"
                    :title="followup.quotedFileContent.value"
                    :subtitle="moment(followup.modifiedTime).fromNow()"
                  >
                    <template v-slot:prepend>
                      <v-btn
                        icon
                        class="mr-2"
                        @click.stop="handleSelectFollowUp(item, followup)"
                      >
                        <v-icon
                          v-if="followup.resolved"
                          icon="mdi-clipboard-check-outline"
                        ></v-icon>
                        <v-icon v-else icon="mdi-clipboard-outline"></v-icon>
                      </v-btn>
                    </template>
                  </v-list-item>
                </v-list>
              </div>

              <div v-else class="text-center">
                <p>No follow ups exist for this file</p>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import wmAddDriveFileButton from "./wmAddDriveFileButton.vue";

import {
  openWindowsHelper,
  focusWindowHelper,
  closeWindowHelper,
  replaceLinkTabHelper,
  // clearLinksHelper,
} from "../modules/uiHelpers.js";

export default {
  emits: [
    "setAutoCreateNote",
    "setManualRefresh",
    "refreshDone",
    "openDriveFilesChanged",
  ],
  created: function () {
    this.moment = moment;
    this.openDriveDocs = this.openDriveDocsParam;
  },
  props: {
    parentTaskId: String,
    parentTaskTitle: String,
    fileListSearchQ: String,
    autoLoad: String,
    showAddNote: String,
    addNoteLabelType: String, // eg. Task
    addNoteLabelId: String, // eg. Task Id of parent
    autoCreateNote: String,
    manualRefresh: Boolean,
    openDriveDocsParam: Array,
  },
  components: {
    wmAddDriveFileButton,
  },
  data() {
    return {
      itemFiles: [],
      panelsSelected: [],

      showFileMenu: [],
      showFileMenuRenameFile: false,
      newFileName: "",
      isRenameValid: false,

      newFolderName: "wotMatters",
      fileSearchText: "",

      showAddFileMenu: false,
      showCreateNoteForm: false,
      isCreateNoteValid: false,
      newNoteName: "",
      // showUploadForm: false,
      // isUploadValid: false,
      // uploadFileName: [],

      openDriveDocs: [],
    };
  },
  watch: {
    getGDriveAccessStatus(newValue) {
      // When we've loaded an access token, load gdrive files
      if (newValue === "granted" && this.autoLoad == "true") {
        this.refreshFiles();
      }
    },
    showFileMenu: {
      handler(newValue) {
        // This watch is needed since I've set the menu to close-on-content-click="false"
        // This is relying on vue to set it to false when I click outside of the menu
        if (newValue.indexOf(true) == -1) {
          this.resetMenu();
        }
      },
      deep: true,
    },
    showAddFileMenu: {
      handler(newValue) {
        // This watch is needed since I've set the menu to close-on-content-click="false"
        // This is relying on vue to set it to false when I click outside of the menu
        if (!newValue) {
          this.resetAddFileMenu();
        }
      },
    },
    autoCreateNote(newValue) {
      if (newValue) {
        this.refreshFiles().then(() => {
          // Check if file has already been created for this event and open that, otherwise create a new file
          if (this.openDriveFileForEvent(newValue) === -1) {
            const eventTitle = this.getCalendarEventById(newValue).summary;
            this.createDriveDoc(eventTitle, newValue);
          }
          this.$emit("setAutoCreateNote", "");
        });
      }
    },
    manualRefresh(newValue) {
      if (newValue) {
        this.refreshFiles();
        this.$emit("setManualRefresh", false);
      }
    },
  },
  computed: {
    ...mapGetters("settings", ["getDriveAppFolder"]),
    ...mapGetters("googleapi", [
      "getGDriveAccessStatus",
      "getCalendarEventById",
      "getCalendarEvents",
    ]),
    ...mapGetters("task", ["getAllTasks", "getTaskById", "getAllTaskGroups"]),
  },
  methods: {
    ...mapActions("googleapi", [
      "startGoogleDriveAuthorize",
      "listFiles",
      "getFile",
      "createDoc",
      "createFile",
      "setFileProperty",
      "renameFile",
      "deleteFile",
      "createAppFolder",
      "listCalendar",
      "listFileComments",
    ]),
    openDriveFileForEvent(eventId) {
      const eventFileIndex = this.itemFiles.findIndex(
        (file) => file.appProperties[eventId] === "event"
      );
      return eventFileIndex;
    },
    createDriveDoc(fileName, eventId) {
      const defaultFileName = "(untitled)";
      const defaultContent = "";
      const defaultTimestamp = new Date();

      this.itemFiles.unshift({
        id: "",
        name: fileName && fileName.length > 0 ? fileName : defaultFileName,
        createdTime: defaultTimestamp,
        modifiedTime: defaultTimestamp,
      });
      this.$emit("refreshDone", this.itemFiles.length);

      this.createDoc({
        fileName: fileName && fileName.length > 0 ? fileName : defaultFileName,
        fileContent: defaultContent,
      }).then((newFile) => {
        // Get the file URL
        this.getFile({ fileId: newFile.id }).then((fileDetails) => {
          // Store newly created file Id in local array, so further updates will work
          this.itemFiles[0].iconLink = fileDetails.iconLink;
          this.itemFiles[0].id = fileDetails.id;
          this.itemFiles[0].webViewLink = fileDetails.webViewLink;

          this.setFileProperty({
            fileId: newFile.id,
            filePropertyKey: this.addNoteLabelId,
            filePropertyValue: this.addNoteLabelType,
          });

          // Only populated if the file is associated with an event
          if (eventId) {
            this.setFileProperty({
              fileId: newFile.id,
              filePropertyKey: eventId,
              filePropertyValue: "event",
            });
          }
        });
      });
    },
    uploadDriveFile(file) {
      const defaultTimestamp = new Date();

      this.itemFiles.unshift({
        id: "",
        name: file.name,
        createdTime: defaultTimestamp,
        modifiedTime: defaultTimestamp,
      });
      this.$emit("refreshDone", this.itemFiles.length);

      this.createFile({ file: file }).then((newFile) => {
        // Get the file URL
        this.getFile({ fileId: newFile.id }).then((fileDetails) => {
          // Store newly created file Id in local array, so further updates will work
          this.itemFiles[0].iconLink = fileDetails.iconLink;
          this.itemFiles[0].id = fileDetails.id;
          this.itemFiles[0].webViewLink = fileDetails.webViewLink;

          this.setFileProperty({
            fileId: newFile.id,
            filePropertyKey: this.addNoteLabelId,
            filePropertyValue: this.addNoteLabelType,
          });
        });
      });
    },
    refreshFiles() {
      return new Promise((resolve, reject) => {
        this.panelsSelected = [];

        // If search text has been enteted, add criteria
        const textSearchStr = this.fileSearchText
          ? this.fileSearchText + "'"
          : "'";

        // If a search Q parameter has been passed from parent, add crteria
        const fileListSearchStr = this.fileListSearchQ
          ? " and " + this.fileListSearchQ
          : "";
        const fullSearchQ =
          "fullText contains '" +
          textSearchStr +
          fileListSearchStr +
          " and mimeType != 'application/vnd.google-apps.folder'";
        this.listFiles({
          searchQ: fullSearchQ,
        })
          .then((retVal) => {
            this.itemFiles = retVal.files;

            // Load followups
            const followUpSearchStr = fileListSearchStr
              ? " and " + "fullText contains 'followup:actionitems'"
              : "fullText contains 'followup:actionitems'";
            const fullFollowUpSearchQ =
              "fullText contains '" +
              textSearchStr +
              followUpSearchStr +
              " and mimeType != 'application/vnd.google-apps.folder'";

            this.listFiles({
              searchQ: fullFollowUpSearchQ,
            })
              .then((retVal) => {
                if (retVal.files.length > 0) {
                  retVal.files.forEach((file) => {
                    this.listFileComments({
                      fileId: file.id,
                    })
                      .then((retVal) => {
                        const updFile = this.itemFiles.find((e) => {
                          return e.id == file.id;
                        });
                        if (updFile) {
                          updFile.followups = retVal.comments;
                        }
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  });
                }

                this.$emit("refreshDone", this.itemFiles.length);
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    startRenameFile(item) {
      this.showFileMenuRenameFile = true;
      this.newFileName = item.name;
    },
    renameDriveFile(item, newFileName) {
      this.renameFile({ fileId: item.id, fileName: newFileName });
      item.name = newFileName;
      item.modifiedTime = new Date();

      // Reset menu flags
      this.resetMenu();
      // }
    },
    removeDriveFile(item) {
      this.deleteFile({ fileId: item.id });

      // Close file (if it's open)
      this.handleCloseDriveDoc(item);

      // Remove the file from the local list
      const fileIndex = this.itemFiles.findIndex((file) => file.id === item.id);
      if (fileIndex > -1) {
        this.itemFiles.splice(fileIndex, 1);
        this.panelsSelected = [];
        this.$emit("refreshDone", this.itemFiles.length);
      }

      // Reset menu flags
      this.resetMenu();
      // }
    },
    resetMenu() {
      // Set any currently open isMenuOpen flags to false
      for (var n = 0; n < this.showFileMenu.length; n++) {
        if (this.showFileMenu[n]) {
          this.showFileMenu[n] = false;
        }
      }

      // HACK: This is a timer as it was showing the top level menu items before closing it, when clicking outside of showing target task
      var v = this;
      setTimeout(function () {
        // v.showFileMenuMoveTargetTask = false
        v.showFileMenuRenameFile = false;
      }, 600);
    },
    handleSelectDriveDoc(file) {
      if (this.$vuetify.display.mobile) {
        window.location.href = file.webViewLink;
      } else {
        const driveDocIndex = this.openDriveDocs.findIndex((e) =>
          e.id.startsWith(file.id.substring(0, 32))
        );

        if (driveDocIndex > -1) {
          // If it's already open, set focus to it, otherwise, open it
          focusWindowHelper(this.openDriveDocs[driveDocIndex].id);
        } else {
          // Open Drive document
          openWindowsHelper(this.parentTaskId, this.parentTaskTitle, [
            {
              linkId: file.id,
              url: file.webViewLink,
            },
          ]);
          this.openDriveDocs.push({
            id: file.id,
            webViewLink: file.webViewLink,
          });

          // Notify parent, so open drive files can be saved
          this.$emit("openDriveFilesChanged", this.openDriveDocs);
        }
      }
    },
    handleCloseDriveDoc(file) {
      const driveDocIndex = this.openDriveDocs.findIndex((e) =>
        e.id.startsWith(file.id.substring(0, 32))
      );

      if (driveDocIndex > -1) {
        closeWindowHelper(
          [this.openDriveDocs[driveDocIndex].id.substring(0, 32)],
          false
        );
        this.openDriveDocs.splice(driveDocIndex, 1);

        // Notify parent, so open drive files can be saved
        this.$emit("openDriveFilesChanged", this.openDriveDocs);
      }
    },
    handleSelectFollowUp(file, followup) {
      // Open Drive document to the requested followup position
      const fullUrl = file.webViewLink.includes("?")
        ? file.webViewLink + "&disco=" + followup.id
        : file.webViewLink + "?disco=" + followup.id;
      const fullId = file.id.substring(0, 32) + "-" + followup.id;

      const driveDocIndex = this.openDriveDocs.findIndex((e) => e.id == fullId);
      if (driveDocIndex > -1) {
        // If it's already open, set focus to it, otherwise, open it
        focusWindowHelper(this.openDriveDocs[driveDocIndex].id);
      } else {
        // Find if any document with same 'root' exists - ie. just the file or the file with a followup
        const rootDocIndex = this.openDriveDocs.findIndex((e) =>
          e.id.startsWith(file.id.substring(0, 32))
        );

        if (rootDocIndex > -1) {
          replaceLinkTabHelper(
            this.parentTaskId,
            this.parentTaskTitle,
            file.id,
            fullId,
            fullUrl
          );

          this.openDriveDocs.splice(rootDocIndex, {
            id: fullId,
            webViewLink: file.webViewLink,
          });
        } else {
          // Open Drive document
          openWindowsHelper(this.parentTaskId, this.parentTaskTitle, [
            {
              linkId: fullId,
              url: fullUrl,
            },
          ]);

          this.openDriveDocs.push({
            id: fullId,
            webViewLink: file.webViewLink,
          });
        }
      }
      // Notify parent, so open drive files can be saved
      this.$emit("openDriveFilesChanged", this.openDriveDocs);
    },
    fileIsOpen(fileId) {
      if (this.openDriveDocs && this.openDriveDocs.length > 0) {
        return this.openDriveDocs.find((e) =>
          e.id.startsWith(fileId.substring(0, 32))
        );
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (
      this.getGDriveAccessStatus === "granted" &&
      this.autoLoad == "true" &&
      this.autoCreateNote == ""
    ) {
      this.refreshFiles();

      // Open drive files as previously saved
      const filesToOpen = [];
      this.openDriveDocs.forEach((file) => {
        filesToOpen.push({
          linkId: file.id,
          url: file.webViewLink,
        });
      });

      // Open Drive document/s
      openWindowsHelper(this.parentTaskId, this.parentTaskTitle, filesToOpen);
    }
  },
};
</script>

<style scoped>
.img-black-and-white {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
</style>